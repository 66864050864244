@font-face {
  font-family: 'DXRigraf-SemBdExpIta';
  src: url('./fonts/DXRigraf-SemBdExpIta.otf') format('opentype');
}

@font-face {
  font-family: 'DXRigraf-SemiBold';
  src: url('./fonts/DXRigraf-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'DXRigraf-SemiBoldExpanded';
  src: url('./fonts/DXRigraf-SemiBoldExpanded.otf') format('opentype');
}

@font-face {
  font-family: 'DXRigraf-SemiBoldItalic';
  src: url('./fonts/DXRigraf-SemiBoldItalic.otf') format('opentype');
}

body {
  font-family: 'DXRigraf-SemiBold', sans-serif;
  /* background-color: #1e1e1e; */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: calc(100vh - 80px);
}

* {
  cursor: none;
}

html {
  background-color: #000;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000;
}

.char {
  transform: translateY(-160px);
  transition: transform 0.3s;
}

.line {
  /* height: 50px; */
  width: auto;
}

.line > a {
  color: #FFF;
}
.line > span {
  color: rgba(255, 255, 255, 0.5);
}

p {

  margin: 0;
}

h1 {
  margin: 0;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color, white);
  border-radius: 5px;
}